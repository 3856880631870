import cx from 'clsx';
import { useState } from 'react';
import { Table, Checkbox, ScrollArea, Group, Avatar, Text, rem, Button } from '@mantine/core';
import classes from './TableSelection.module.css';
import dodge from "../assets/Dodge.jpg";
import golf from "../assets/golf.jpg";
import cupra from "../assets/cupra.jpg";
import mercedes from "../assets/mercedes.jpg";
import { SplitButton } from './AdminButton';


export function TableSelection({setShowConfModal, setCarToDelete, setCarToUpdate, data, setMode, setCarToEdit }: any)  {
    const [selection, setSelection] = useState(['']);

    console.log(data);
    const toggleRow = (id: string) =>
        setSelection((current) =>
            current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
        );
    const toggleAll = () =>
        setSelection((current) => (current.length === data.length ? [] : data.map((item: { id: any; }) => item.id)));

    const rows = data.map((item : any) => {
        const selected = selection.includes(item.id);
        return (
            <Table.Tr key={item.id} className={cx({ [classes.rowSelected]: selected })}>
                <Table.Td>
                    <Checkbox color='#3459e1' checked={selection.includes(item.id)} onChange={() => toggleRow(item.id)} />
                </Table.Td>
                <Table.Td><Avatar size={36} src={`https://carmatchriviera.fr/api/public/uploads/${item.images[0].name}`} /></Table.Td>
                <Table.Td>
                    <Group gap="sm">
                        {/* <Avatar size={30} src={item.image} radius={26} /> */}
                        <Text size="sm" fw={500}>
                            {item.make}
                        </Text>
                    </Group>
                </Table.Td>
                <Table.Td>{item.year}</Table.Td>
                <Table.Td>{item.config}</Table.Td>
                <Table.Td>{item.price}</Table.Td>
                <Table.Td>{item.place}</Table.Td>
                <Table.Td>{item.transmission}</Table.Td>
                <Table.Td>{item.carburant}</Table.Td>
                <Table.Td>{item.garantie}</Table.Td>
                <Table.Td>               <Group gap="sm">
                        {/* <Avatar size={30} src={item.image} radius={26} /> */}
                        <Text size="sm" fw={500}>
                            {item.kilo}
                        </Text>
                    </Group></Table.Td>
                <Table.Td> <SplitButton setShowConfModal={setShowConfModal} setCarToDelete={setCarToDelete} setCarToUpdate={setCarToUpdate} item={item} setMode={setMode} setCarToEdit={setCarToEdit} /></Table.Td>
            </Table.Tr>
        );
    });



    return (
        <ScrollArea>
            <Table miw={900} verticalSpacing="sm">
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th style={{ width: rem(40) }}>
                            <Checkbox
                                color='#3459e1'
                                onChange={toggleAll}
                                checked={selection.length === data.length}
                                indeterminate={selection.length > 0 && selection.length !== data.length}
                            />
                        </Table.Th>
                        <Table.Th></Table.Th>
                        <Table.Th>Nom</Table.Th>
                        <Table.Th>Année</Table.Th>
                        <Table.Th>Config</Table.Th>
                        <Table.Th>Prix(€)</Table.Th>
                        <Table.Th>Place</Table.Th>
                        <Table.Th>Transmission</Table.Th>
                        <Table.Th>Carburant</Table.Th>
                        <Table.Th>Garantie(mois)</Table.Th>
                        <Table.Th>Kilometrage(km)</Table.Th>
                        <Table.Th>Action</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
            </Table>
        </ScrollArea>
    );
}